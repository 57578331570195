import React, { SyntheticEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import FrontEndContext from "../../context/FrontEndContext";
import Page from "../../components/Page";
import "./index.css";
import { Box, AlertColor } from "@mui/material";
import { Form } from "react-bootstrap";
import config, { ApiUserData } from "../../config";
import Utils from "../../utils";
import CustomSnackbar from "../../components/CustomSnackbar";
import CustomButton from "../../components/CustomButton";
import BackButton from "../../components/BackButton";

const { ID_CREATE_NEW } = config?.app;
const { CREATE_USERS, UPDATE_USER, API_FAIL_ERROR } = config?.api;

const HEADER_NAME = "";

const User = () => {
  const { userId } = useParams();
  const navigate = useNavigate();
  const {
    refreshUsers,
    isAuthenticated,
    isAuthenticating,
    users,
    companies,
    headersWithAuth,
  } = React.useContext(FrontEndContext);

  const isCreatingNew = ID_CREATE_NEW === userId;

  const [initialUserData, setInitialUserData] = useState<
    ApiUserData | undefined
  >(undefined);
  const [updatedUserData, setUpdatedUserData] = useState<
    ApiUserData | undefined
  >(undefined);
  const [newPassword, setNewPassword] = useState<string>("");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<AlertColor>("success");
  const [isLoading, setIsLoading] = useState(false);

  const handleSnackbarClose = (
    event: SyntheticEvent<any, Event> | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const isUserChanged = (): boolean => {
    const noChange =
      !!initialUserData &&
      !!updatedUserData &&
      Object.is(initialUserData, updatedUserData);
    return !noChange;
  };

  const passwordCriteria = (password: string): any => ({
    minLength: { passes: password.length >= 8, desc: "8 characters" },
    capital: {
      passes: password.search(/.*[A-Z]/g) >= 0,
      desc: "one uppercase",
    },
    lower: { passes: password.search(/.*[a-z]/g) >= 0, desc: "one lowercase" },
    number: { passes: password.search(/\d/g) >= 0, desc: "one digit" },
  });

  const check = String.fromCodePoint(0x02713);
  const ex = String.fromCodePoint(0x02717);

  const passwordCriteriaString = (password: string) => {
    const criteria = passwordCriteria(password);

    return (
      <div>
        <small
          className={`${
            criteria.minLength.passes ? "text-success" : "text-danger"
          }`}
        >
          {criteria.minLength.passes ? check : ex}&nbsp;
          {criteria.minLength.desc}
        </small>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <small
          className={`${
            criteria.number.passes ? "text-success" : "text-danger"
          }`}
        >
          {criteria.number.passes ? check : ex}&nbsp;
          {criteria.number.desc}
        </small>
        <br />
        <small
          className={`${
            criteria.capital.passes ? "text-success" : "text-danger"
          }`}
        >
          {criteria.capital.passes ? check : ex}&nbsp;
          {criteria.capital.desc}
        </small>
        &nbsp;&nbsp;
        <small
          className={`${
            criteria.lower.passes ? "text-success" : "text-danger"
          }`}
        >
          {criteria.lower.passes ? check : ex}&nbsp;
          {criteria.lower.desc}
        </small>
      </div>
    );
  };

  const validateEmail = (email?: string) =>
    !!email &&
    email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    ) !== null;

  const validatePassword = (password: string) =>
    passwordCriteria(password).minLength.passes &&
    passwordCriteria(password).capital.passes &&
    passwordCriteria(password).lower.passes &&
    passwordCriteria(password).number.passes;

  const validateCompanyId = (companyId?: number) =>
    companyId !== undefined && companyId >= 0;

  const validateFirstName = (firstName?: string) =>
    !firstName || firstName.length <= 64;

  const validateLastName = (lastName?: string) =>
    !lastName || lastName.length <= 64;

  const isDataValid = (): boolean =>
    validateEmail(updatedUserData?.email) &&
    (validatePassword(newPassword) || !isCreatingNew) &&
    validateFirstName(updatedUserData?.firstName) &&
    validateLastName(updatedUserData?.lastName) &&
    validateCompanyId(updatedUserData?.companyId);

  useEffect(() => {
    if (!isAuthenticating && !isAuthenticated) {
      navigate("/login");
    }
  });

  useEffect(() => {
    if (users) {
      const userFromId = users.find((user) => user.id.toString() === userId);
      setUpdatedUserData(userFromId);
      setInitialUserData(userFromId);
    }
  }, [userId, users]);

  const handleUpdate = (
    e: React.ChangeEvent<HTMLInputElement>,
    prop: string
  ) => {
    if (prop === "password") {
      setNewPassword(e.target.value);
    } else {
      const newUserData: ApiUserData = { ...(updatedUserData as ApiUserData) };

      if (prop === "companyId") {
        newUserData.companyId = parseInt(e.target.value);
      } else {
        (newUserData as any)[prop] = e.target.value;
      }

      console.log(`After update newUserData:`, newUserData);
      setUpdatedUserData(newUserData);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const sendRequest = async () => {
      const webUsers = [
        {
          ...updatedUserData,
          ...(isCreatingNew ? { password: newPassword } : { password: " " }),
        },
      ];

      console.log(`webUsers:`, webUsers);
      let userAction = isCreatingNew ? "Created" : "Updated";
      try {
        setOpenSnackbar(true);
        setSnackbarMessage(
          `Started ${isCreatingNew ? "creating" : "updating"} the user: ${
            updatedUserData?.email
          } ...`
        );
        setSnackbarSeverity("success");
        let createUserResponse;

        if (!isCreatingNew) {
          console.log("entered update condition");

          createUserResponse = await axios.post(
            `${UPDATE_USER}`,
            {
              id: updatedUserData?.id,
              firstName: updatedUserData?.firstName,
              lastName: updatedUserData?.lastName,
            },
            headersWithAuth
          );
        } else {
          createUserResponse = await axios.post(
            `${CREATE_USERS}`,
            { webUsers },
            headersWithAuth
          );
          console.log("entered create condition");
        }
        if (createUserResponse?.status >= 400) {
          setSnackbarMessage(
            `${userAction} User  ${API_FAIL_ERROR}${createUserResponse?.status}`
          );
          setSnackbarSeverity("error");
        } else {
          setSnackbarMessage(`User ${userAction} successfully!`);
          setSnackbarSeverity("success");
          return true;
        }
      } catch (e) {
        setSnackbarMessage(`${userAction} User ${API_FAIL_ERROR}${e}`);
        setSnackbarSeverity("error");
      } finally {
        setIsLoading(false);
      }
    };

    await sendRequest();
    refreshUsers();
    setUpdatedUserData(undefined);
    setNewPassword("");
    setIsLoading(false);
    setOpenSnackbar(true);
  };

  const pageTitle = isCreatingNew ? `New User` : `User Id: ${userId}`;

  const email = updatedUserData?.email || "";
  const firstName = updatedUserData?.firstName || "";
  const lastName = updatedUserData?.lastName || "";
  const companiesOptions = Utils.getCompaniesOptions(
    companies,
    updatedUserData?.companyId
  );

  return (
    <Page name={HEADER_NAME} isAuthenticating={isAuthenticating}>
      <Box className="home-container py-3">
        <Box sx={{ padding: 2 }}>
          <h1>{pageTitle}</h1>
          <hr />
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formEmail">
              <Form.Label>Email</Form.Label>
              <Form.Control
                type="text"
                value={email}
                onChange={(e: any) => handleUpdate(e, "email")}
              />
              <small
                className={`${
                  validateEmail(email) ? "invisible" : "visible"
                } text-danger`}
              >
                Invalid email address
              </small>
            </Form.Group>

            {isCreatingNew && (
              <Form.Group className="mb-3" controlId="formPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="text"
                  value={newPassword}
                  onChange={(e: any) => handleUpdate(e, "password")}
                />
                {passwordCriteriaString(newPassword)}
              </Form.Group>
            )}

            <Form.Group className="mb-3" controlId="formFirstName">
              <Form.Label>First Name</Form.Label>
              <Form.Control
                type="text"
                value={firstName}
                onChange={(e: any) => handleUpdate(e, "firstName")}
              />
              <small
                className={`${
                  validateFirstName(firstName) ? "invisible" : "visible"
                } text-danger`}
              >
                First name too long (maximum 64 characters)
              </small>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formLastName">
              <Form.Label>Last Name</Form.Label>
              <Form.Control
                type="text"
                value={lastName}
                onChange={(e: any) => handleUpdate(e, "lastName")}
              />
              <small
                className={`${
                  validateLastName(lastName) ? "invisible" : "visible"
                } text-danger`}
              >
                Last name too long (maximum 64 characters)
              </small>
            </Form.Group>

            <Form.Group className="mb-3" controlId="formCompany">
              <Form.Label>Company</Form.Label>
              <Form.Select
                disabled={companies.length === 0}
                aria-label="Company selection list"
                value={(updatedUserData?.companyId !== undefined
                  ? updatedUserData?.companyId
                  : companiesOptions[0]?.props?.value || "unknown"
                ).toString()}
                onChange={(e: any) => handleUpdate(e, "companyId")}
              >
                {companiesOptions}
              </Form.Select>
            </Form.Group>
            <Box display="flex" gap={2}>
              <BackButton>Back</BackButton>
              <CustomButton
                type="submit"
                disabled={!isUserChanged() || isLoading || !isDataValid()}
                isLoading={isLoading}
                text="Submit"
              />
            </Box>
          </Form>
        </Box>
      </Box>

      <CustomSnackbar
        open={openSnackbar}
        handleClose={handleSnackbarClose}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </Page>
  );
};

export default User;
