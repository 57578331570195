import React, { useState, FC } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";
import CustomButton from "../CustomButton";

interface IButtonWithDialogProps {
  onDelete: () => void;
  typeOfOperation: string;
}

const ButtonWithDialogProps: FC<IButtonWithDialogProps> = ({
  onDelete,
  typeOfOperation,
}) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDelete = () => {
    onDelete();
    setOpen(false);
  };

  return (
    <div>
      <CustomButton
        variant="contained"
        onClick={handleClickOpen}
        // text={typeOfOperation}
        icon={DeleteForeverRoundedIcon}
        iconColor="red"
      />
      {/* <Button variant="contained" color="warning" onClick={handleClickOpen}>
        {typeOfOperation}
      </Button> */}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{`${typeOfOperation} Confirmation`}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {`Are you sure you want to ${typeOfOperation} this?`}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleDelete} color="primary" autoFocus>
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ButtonWithDialogProps;
