import { useNavigate } from "react-router-dom";
import MUIDataTable, {
  FilterType,
  Responsive,
  SelectableRows,
} from "mui-datatables";
import { Box, createTheme, ThemeProvider } from "@mui/material";
import "./index.css";
import { ApiOperationHistory } from "../../config";
import { useEffect } from "react";
import React from "react";
import FrontEndContext from "../../context/FrontEndContext";

interface OperationHistoryProps {
  operationHistoryValid: boolean;
  operationHistories?: ApiOperationHistory[];
}

export default function OperationHistoryList(props: OperationHistoryProps) {
  const { operationHistoryValid, operationHistories } = props;
  const navigate = useNavigate();
  const data = (operationHistoryValid && operationHistories) || [];

  function formatLastLogin(lastLoginTime: string): string {
    if (!lastLoginTime) return "-";
    const date = new Date(lastLoginTime);
    return date.toLocaleString();
  }

  const dataWithLastLogin = data.map((user) => ({
    ...user,
    timestamp: formatLastLogin(user.timestamp),
  }));
  //const {isAuthenticated, isAuthenticating, refreshOperationHistories, headersWithAuth } = React.useContext(FrontEndContext);
  const { isAuthenticated, isAuthenticating } =
    React.useContext(FrontEndContext);
  useEffect(() => {
    if (!isAuthenticating && !isAuthenticated) {
      navigate("/login");
    }
  });
  const options = {
    setCellProps: () => ({
      align: "left",
    }),
  };

  const COLUMNS = [
    { label: "USER NAME", name: "userName", options },
    { label: "ENTITY TYPE", name: "entityType", options },
    { label: "OPERATION", name: "operation", options },
    { label: "OPERATION VALUES", name: "operationValues", options },
    { label: "TIMESTAMP", name: "timestamp", options },
  ];

  const OPTIONS = {
    download: false,
    filter: true,
    filterType: "dropdown" as FilterType,
    pagination: true,
    print: false,
    responsive: "vertical" as Responsive,
    search: true,
    selectableRows: "single" as SelectableRows,
    selectableRowsHideCheckboxes: true,
    storageKey: "operationHistories",
    textLabels: {
      body: {
        noMatch: operationHistoryValid
          ? "No Operation History available."
          : "Loading ...",
      },
    },
  };

  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTableHeadCell: {
          styleOverrides: {
            contentWrapper: {
              justifyContent: "left",
            },
          },
        },
      },
    });
  return (
    <Box className="systems-table">
      <ThemeProvider theme={getMuiTheme()}>
        <MUIDataTable
          title={""}
          data={dataWithLastLogin}
          columns={COLUMNS}
          options={OPTIONS}
        />
      </ThemeProvider>
    </Box>
  );
}
