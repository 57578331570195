import MUIDataTable, { FilterType, Responsive, SelectableRows } from "mui-datatables";
import { Box, createTheme, ThemeProvider } from "@mui/material";
import "./index.css";
import {  ApiErrorLogData } from "../../config";


interface ErrorLogsListProps {
  errorLogsValid: boolean;
  errorLogsList?: ApiErrorLogData[];
}

export default function ErrorLogsList(props: ErrorLogsListProps) {
  const { errorLogsValid, errorLogsList } = props;
  // const navigate = useNavigate();
  const data = (errorLogsValid && errorLogsList) || [{id:1, errorDescription:"1-Still in development-Test Message"}, {id:2, errorDescription:"2-Still in development- Test Message"}];

  const options = {
    setCellProps: () => ({
      align: "left",
    }),
  };

  


  const COLUMNS = [

    { label: "ID", name: "id", options },
    { label: "DESCRIPTION", name: "errorDescription", options },
  ];

  const OPTIONS = {
    download: false,
    filter: true,
    filterType: "dropdown" as FilterType,
    pagination: false,
    print: false,
    responsive: "vertical" as Responsive,
    search: true,
    selectableRows: "single" as SelectableRows,
    selectableRowsHideCheckboxes: true,
    storageKey: "ErrorLogs",
    textLabels: {
      body: {
        noMatch: errorLogsValid ? "No ErrorLogs available." : "Loading ...",
      },
    },
  };

  const getMuiTheme = () =>
    createTheme({
      components: {
        MUIDataTableHeadCell: {
          styleOverrides: {
            contentWrapper: {
              justifyContent: "left",
            },
          },
        },
      },
    });
  return (
    <Box className="systems-table">
      <ThemeProvider theme={getMuiTheme()}>
        <MUIDataTable title={""} data={data} columns={COLUMNS} options={OPTIONS} />
      </ThemeProvider>
    </Box>
  );
}
