import React, { SyntheticEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import FrontEndContext from "../../context/FrontEndContext";
import Page from "../../components/Page";
import "./index.css";
import { Box, AlertColor } from "@mui/material";
import { Form } from "react-bootstrap";
import config, { ApiCompanyData } from "../../config";

import CustomSnackbar from "../../components/CustomSnackbar";
import CustomButton from "../../components/CustomButton";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

const { ID_CREATE_NEW } = config?.app;
//const { CREATE_COMPANY, API_FAIL_ERROR } = config?.api;
const { CREATE_COMPANY, UPDATE_COMPANY, API_FAIL_ERROR } = config?.api;
const HEADER_NAME = "";

const Company = () => {
  const { companyId } = useParams();
  const navigate = useNavigate();
  const {
    isAuthenticated,
    isAuthenticating,
    refreshCompanies,
    companies,
    headersWithAuth,
  } = React.useContext(FrontEndContext);
  // adding new code for back button
  const isCreatingNew = companyId === ID_CREATE_NEW;

  const [initialCompanyData, setInitialCompanyData] = useState<
    ApiCompanyData | undefined
  >(undefined);
  const [updatedCompanyData, setUpdatedCompanyData] = useState<
    ApiCompanyData | undefined
  >(undefined);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] =
    useState<AlertColor>("success");
  const [isLoading, setIsLoading] = useState(false);

  const handleSnackbarClose = (
    event: SyntheticEvent<any, Event> | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSnackbar(false);
  };

  const isCompanyChanged = (): boolean => {
    const noChange =
      !!initialCompanyData &&
      !!updatedCompanyData &&
      Object.is(initialCompanyData, updatedCompanyData);
    return !noChange;
  };

  const validateCompanyName = (companyName?: string) =>
    !companyName || companyName.length <= 64;

  const isDataValid = (): boolean =>
    validateCompanyName(updatedCompanyData?.name);

  useEffect(() => {
    if (!isAuthenticating && !isAuthenticated) {
      navigate("/login");
    }
  });

  useEffect(() => {
    if (companies) {
      const companyFromId = companies.find(
        (company) => company.id.toString() === companyId
      );
      setUpdatedCompanyData(companyFromId);
      setInitialCompanyData(companyFromId);
    }
  }, [companyId, companies]);

  const handleUpdate = (
    e: React.ChangeEvent<HTMLInputElement>,
    prop: string
  ) => {
    const newCompanyData: ApiCompanyData = {
      ...(updatedCompanyData as ApiCompanyData),
    };

    (newCompanyData as any)[prop] = e.target.value;

    console.log(`After update newCompanyData:`, newCompanyData);
    setUpdatedCompanyData(newCompanyData);
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const sendRequest = async () => {
      const companies = [
        {
          id: updatedCompanyData?.id,
          name: updatedCompanyData?.name,
          ...(updatedCompanyData?.address1
            ? { address1: updatedCompanyData?.address1 }
            : {}),
          ...(updatedCompanyData?.address2
            ? { address2: updatedCompanyData?.address2 }
            : {}),
          ...(updatedCompanyData?.city
            ? { city: updatedCompanyData?.city }
            : {}),
        },
      ];

      let userAction = isCreatingNew ? "Created" : "Updated";
      try {
        setIsLoading(true);

        setOpenSnackbar(true);
        setSnackbarMessage(
          `Started ${isCreatingNew ? "creating" : "updating"} the user: ${
            updatedCompanyData?.name
          } ...`
        );
        setSnackbarSeverity("success");
        let createCompanyResponse;
        if (!isCreatingNew) {
          console.log("entered update condition");
          createCompanyResponse = await axios.post(
            `${UPDATE_COMPANY}`,
            {
              id: updatedCompanyData?.id,
              name: updatedCompanyData?.name,
              ...(updatedCompanyData?.address1
                ? { address1: updatedCompanyData?.address1 }
                : {}),
              ...(updatedCompanyData?.address2
                ? { address2: updatedCompanyData?.address2 }
                : {}),
              ...(updatedCompanyData?.city
                ? { city: updatedCompanyData?.city }
                : {}),
            },
            headersWithAuth
          );
        } else {
          createCompanyResponse = await axios.post(
            `${CREATE_COMPANY}`,
            { companies },
            headersWithAuth
          );
          console.log("entered create condition");
        }
        if (createCompanyResponse?.status >= 400) {
          //console.log(createCompanyResponse);
          console.log(
            `${userAction} ${config.api.API_FAIL_ERROR}${createCompanyResponse.status}`
          );
          setSnackbarMessage(
            `${userAction} Company  ${API_FAIL_ERROR}${createCompanyResponse?.status}`
          );
          setSnackbarSeverity("error");
        } else {
          console.log(`${userAction}`);
          setSnackbarMessage(`Company ${userAction} successfully!`);
          setSnackbarSeverity("success");
          return true;
        }
        //const newCompanyData: ApiCompanyData = response.data;
      } catch (e) {
        console.log(`${userAction} ${config.api.API_FAIL_ERROR}`);
        setSnackbarMessage(`${userAction} Company ${API_FAIL_ERROR}${e}`);
        setSnackbarSeverity("error");
      }
    };

    await sendRequest();
    refreshCompanies();
    setUpdatedCompanyData(undefined);
    setIsLoading(false);
    setOpenSnackbar(true);
  };

  const pageTitle = isCreatingNew ? `New Company` : `Company Id: ${companyId}`;

  const name = updatedCompanyData?.name || "";
  const address1 = updatedCompanyData?.address1 || "";
  const address2 = updatedCompanyData?.address2 || "";
  const city = updatedCompanyData?.city || "";

  return (
    <Page name={HEADER_NAME} isAuthenticating={isAuthenticating}>
      <Box className="home-container py-3">
        <Box sx={{ padding: 2 }}>
          <h1>{pageTitle}</h1>
          <hr />
          <Form onSubmit={handleSubmit}>
            <Form.Group className="mb-3" controlId="formName">
              <Form.Label>Name</Form.Label>
              <Form.Control
                type="text"
                value={name}
                onChange={(e: any) => handleUpdate(e, "name")}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formAddress1">
              <Form.Label>Address line 1</Form.Label>
              <Form.Control
                type="text"
                value={address1}
                onChange={(e: any) => handleUpdate(e, "address1")}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formAddress2">
              <Form.Label>Address line 2</Form.Label>
              <Form.Control
                type="text"
                value={address2}
                onChange={(e: any) => handleUpdate(e, "address2")}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="formCity">
              <Form.Label>City</Form.Label>
              <Form.Control
                type="text"
                value={city}
                onChange={(e: any) => handleUpdate(e, "city")}
              />
            </Form.Group>

            <Box display="flex" gap={2}>
              {/* <BackButton>Back</BackButton> */}

              <CustomButton
                size="lg"
                type="button"
                isLoading={isLoading}
                variant="secondary"
                text="Go Back"
                icon={ArrowBackIcon}
                onClick={() => navigate(-1)}
              />

              <CustomButton
                size="lg"
                type="submit"
                disabled={!isCompanyChanged() || !isDataValid() || isLoading}
                isLoading={isLoading}
                text="Submit"
              />
            </Box>
          </Form>
        </Box>
      </Box>

      <CustomSnackbar
        open={openSnackbar}
        handleClose={handleSnackbarClose}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </Page>
  );
};

export default Company;
