import React from "react";
import {
  ApiCompanyData,
  ApiUserData,
  ApiSystemData,
  ApiErrorLogData,
  ApiOperationHistory,
  PresignedUrlResponse
} from "../config";

export interface FrontEndContextInterface {
  handleAuthentication: (userSession: any, error?: any) => void;
  headersWithAuth: any;
  isAuthenticating: boolean;
  isAuthenticated: boolean;
  doLogin: () => void;
  doLogout: () => void;
  companiesValid: boolean;
  companies: ApiCompanyData[];
  usersValid: boolean;
  users: ApiUserData[];
  systemsValid: boolean;
  systems: ApiSystemData[];
  preSigned: PresignedUrlResponse[];
  operationHistoryValid: boolean;
  errorLogsValid: boolean;
  errorLogs: ApiErrorLogData[];
  operationHistories: ApiOperationHistory[];
  refreshUsers: () => Promise<void>;
  refreshSystems: () => Promise<void>;
  refreshCompanies: () => Promise<void>;
  refreshOperationHistories: () => Promise<void>;
}

const FrontEndContext = React.createContext<FrontEndContextInterface>({
  handleAuthentication: (userSession: any, error?: any) => {},
  headersWithAuth: {},
  isAuthenticating: false,
  isAuthenticated: false,
  doLogin: () => {},
  doLogout: () => {},
  companiesValid: false,
  companies: [],
  usersValid: false,
  users: [],
  systemsValid: false,
  systems: [],
  preSigned: [],
  operationHistoryValid: false,
  operationHistories: [],
  errorLogsValid: false,
  errorLogs: [],
  refreshUsers: async () => {},
  refreshSystems: async () => {},
  refreshCompanies: async () => {},
  refreshOperationHistories: async () => {},
});

export default FrontEndContext;
